@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300;1,400&display=swap');

:root {
  --bs-body-color: #333333;
  --bs-body-bg-color: #f3f3f3;
  --bs-white: #ffffff;
  --bs-black: #000000;
  --bs-black-100: #060326;
  --bs-black-200: #4a4a4a;
  --bs-black-300: #121212;
  --bs-black-400: #404040;
  --bs-gray-100: #a7a5a5;
  --bs-gray-200: #ebebeb;
  --bs-gray-300: #808080;
  --bs-gray-400: #ededed;
  --bs-gray-500: #d9d9d9;
  --bs-primary: #219ebc;
  --bs-new-primary: #fb8500;
  --bs-gray-700: #525252;
  --bs-primary-dark: #1793b1;
  --bs-secondary: #2f80ed;
  --bs-black-500: #31393c;
  --bs-primary-light: #dff9ff;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-primary-rgb: 33, 158, 188;
  --bs-gray-rgb: 18, 106, 211;
  --bs-border-color: #adadad;
  --bs-body-font-family: 'Lato', sans-serif;
}

body {
  font-family: var(--bs-body-font-family);
  color: var(--bs-body-color);
  /* background-color: var(--bs-body-bg-color); */
  font-size: 0.875rem;
}

a,
.pointer {
  cursor: pointer !important;
}

a {
  outline: none;
  text-decoration: none;
  color: var(--bs-primary);
}

a:hover,
.btn.link:hover {
  color: var(--bs-primary) !important;
}

img {
  max-width: 100%;
  height: auto;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--bs-black);
  font-weight: 700;
  line-height: 1.3;
}

h1,
.h1 {
  font-size: 3.125rem;
  font-weight: 600 !important;
}

h2,
.h2 {
  font-size: 2.813rem;
}

h3,
.h3 {
  font-size: 1.5rem;
}

h4,
.h4 {
  font-size: 1.25rem;
}

h5,
.h5 {
  font-size: 1.125rem;
}

h6,
.h6 {
  font-size: 1rem;
}

hr {
  border-top: 0.063rem solid var(--bs-gray-500);
  opacity: 1;
}

.custom-hr {
  border-top: 1px solid #eaebeb;
  background: #fff;
  margin: 2rem 0;
}

/* ********************************************************************************************
  Colors
*********************************************************************************************** */
.text-primary {
  color: var(--bs-primary) !important;
}

.text-black-300 {
  color: var(--bs-black-300);
}

.text-black-400 {
  color: var(--bs-black-400);
}

.text-gray-300 {
  color: var(--bs-gray-300);
}

.text-black-opacity-40 {
  color: rgba(var(--bs-black-rgb), 0.4);
}

.text-primary-opacity-50 {
  color: rgba(var(--bs-primary-rgb), 0.5) !important;
}

.sub-title-clr {
  color: var(--bs-gray-700);
}

.success-text {
  color: #4caf50;
}

.light-gray-text {
  color: var(--bs-gray-100);
}

.progress-text {
  color: var(--bs-black-500);
}

.dark-gray {
  color: #636363;
}

.new-gray {
  color: #a8a8a8;
}

.Gray26 {
  color: #424242;
}

.secondary-color {
  color: var(--bs-secondary);
}

.text-new-primary {
  color: var(--bs-new-primary);
}

/* ********************************************************************************************
  Font Size
*********************************************************************************************** */
.font-size-11 {
  font-size: 0.688rem !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(49, 57, 60, 0.1);
}

/* ********************************************************************************************
  Button
*********************************************************************************************** */
.btn {
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
}

.login-box-body .btn {
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.813rem 1rem;
}

.btn-primary {
  background: var(--bs-new-primary) !important;
  border: 0.063rem solid var(--bs-new-primary) !important;
}

.btn-primary:hover {
  background: #e0831b !important;
  border: 0.063rem solid #e0831b !important;
}

.btn-outline-primary {
  color: var(--bs-new-primary);
  border: 0.063rem solid var(--bs-new-primary) !important;
}

.btn-outline-primary:hover {
  background: var(--bs-primary) !important;
}

.btn-outline-secondary {
  border-radius: 3px;
  border: 1px solid #525252;
  color: #525252;
  padding: 13px 24px;
}

.btn-outline-secondary.secondary-radius {
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 16px 8px 12px;
}

.btn-outline-secondary.secondary-radius:hover {
  background-color: transparent;
  color: #525252;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  opacity: 0.5;
}

.btn-light {
  border-radius: 8px;
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  padding: 8px 16px;
  color: var(--bs-black-500);
  font-size: 16px;
}

.btn-gray {
  border-radius: 8px;
  background: #ebebeb;
  border: 1px solid #ebebeb;
  padding: 8px 16px;
  color: var(--bs-black-500);
  font-size: 0.875rem;
}

.btn-gray:hover {
  background: #efefef;
}

/* ********************************************************************************************
  Form
*********************************************************************************************** */
.form-label {
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--bs-black-400);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-control {
  border-radius: 0.5rem;
  font-size: 0.875rem;
  padding: 0.72rem 1rem;
  border: 1px solid var(--bs-gray-100);
}

.form-control:focus {
  border-color: var(--bs-border-color);
  box-shadow: 0 0 0.25rem 0 rgba(var(--bs-black-rgb), 0.25);
}

::-webkit-input-placeholder {
  color: var(--bs-gray-100) !important;
}

::-moz-placeholder {
  color: var(--bs-gray-100) !important;
}

:-ms-input-placeholder {
  color: var(--bs-gray-100) !important;
}

:-moz-placeholder {
  color: var(--bs-gray-100) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: var(--bs-white) !important;
  -webkit-text-fill-color: var(--bs-body-color) !important;
  transition: background-color 5000s ease-in-out 0s;
}

.input-group .input-group-text {
  font-size: 14px;
  color: #6d6d6d;
}

/* ********************************************************************************************
  Form Checkbox
*********************************************************************************************** */

.form-check-input[type='checkbox'] {
  border: 2px solid #404040;
  border-radius: 0.3rem !important;
  width: 1rem;
  height: 1rem;
  box-shadow: none !important;
}

.form-check-input:checked[type='checkbox'] {
  /* background-color: #cecece;
  border-color: #cecece; */
  background-color: var(--bs-new-primary);
  border-color: var(--bs-new-primary);
}

.form-check-inline .form-check-label {
  vertical-align: -webkit-baseline-middle;
  margin-left: 6px;
}

.notification-setting .form-check-label {
  margin-left: 14px;
}

/* ********************************************************************************************
  Form Radio
*********************************************************************************************** */

.form-check-input[type='radio'] {
  background-image: none !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: var(--bs-black);
  box-shadow: none;
  width: 18px;
  height: 18px;
}

.form-check-input:checked[type='radio']::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  background-color: var(--bs-black);
}

.form-check-input:checked[type='radio'] {
  background-color: #ffffff;
}

.form-check .form-check-label {
  margin-left: 6px;
  color: var(--bs-black);
}

/* ********************************************************************************************
  Form Select
*********************************************************************************************** */

.form-select {
  border-radius: 0.5rem;
  font-size: 0.875rem;
  padding: 0.69rem 1rem;
  color: var(--bs-black-400);
}

.form-select:focus {
  border-color: var(--bs-border-color);
  box-shadow: 0 0 0.25rem 0 rgba(var(--bs-black-rgb), 0.25);
}

/* ********************************************************************************************
  Login
*********************************************************************************************** */

.bg-image {
  position: relative;
}

.bg-image::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  content: '';
  /* filter: grayscale(96%); */
  background-image: url(../assets/images/login_bg_image.png);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: contain;
}

.login-box {
  background-color: var(--bs-white);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  /* border-radius: 10.313rem 0 0 0; */
  box-shadow: 0.25rem 0.25rem 0.25rem rgba(var(--bs-white-rgb), 0.25);
}

.login-box h1 {
  color: var(--bs-black-100);
}

.login-box .login-box-body {
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 30px;
}

.text-part {
  margin-bottom: 2.188rem;
}

.login-box .btn {
  min-width: 9.063rem;
}

.or-line {
  position: relative;
  text-align: center;
  max-width: 100%;
  border: 0.063rem solid #f2f2f2;
  margin-top: 10px;
  margin-bottom: 36px;
}

.or-line span {
  position: absolute;
  top: -0.65rem;
  background-color: var(--bs-white);
  padding: 0 0.25rem;
  left: 46%;
  right: 50%;
}

.login-logo {
  width: auto;
  height: auto;
  /* position: absolute;
  top: 3rem;
  left: 4.375rem; */
}

.password-group,
.mobile-group {
  position: relative;
}

.password-group .eye-icon,
.mobile-group .custom-input-group-text {
  position: absolute;
  top: 1.865rem;
  right: 0.125rem;
  height: 2.75rem;
  width: 2.75rem;
  line-height: 0.125rem;
  font-size: 1rem;
  padding: 0;
  text-align: center;
  cursor: pointer;
  min-width: auto;
  color: var(--bs-body-color);
}

.password-group .warning-icon {
  position: absolute;
  top: 2.5rem;
  right: 1.3rem;
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
}

.password-group .eye-icon:hover {
  color: var(--bs-primary);
}

.password-group .form-control {
  padding-right: 3rem;
}

.mobile-group .form-control {
  padding-left: 4rem;
}

.mobile-group .custom-input-group-text {
  left: 1rem;
  right: auto;
  cursor: auto;
  line-height: 2.75rem;
  font-size: 0.875rem;
}

.progress {
  position: absolute;
  left: 0;
  border-radius: 10px;
  bottom: -0.125rem;
  height: 5px;
  width: 100%;
  background-color: #e6e6e6;
}

.progress-bar {
  background-color: var(--bs-primary);
}

.skills-match-bar {
  height: 0.5rem;
  background-color: #ededed;
  border-radius: 1rem;
}

.skills-match-bar.good .progress-bar {
  height: 0.5rem;
  border-radius: 1rem;
  background-color: #4caf50;
}
.skills-match-bar.average .progress-bar {
  height: 0.5rem;
  border-radius: 1rem;
  background-color: #fbd257;
}
.skills-match-bar.poor .progress-bar {
  height: 0.5rem;
  border-radius: 1rem;
  background-color: #f24a4a;
}

.otp-box input {
  height: 2.5rem;
  width: 2.5rem !important;
  padding: 0.375rem 0.5rem;
}

.otp-box span {
  margin: 0 0.438rem;
}

/* ********************************************************************************************
  Modal
*********************************************************************************************** */
.modal {
  background: rgba(0, 0, 0, 0.3);
}

.modal-content {
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
}

.modal-header,
.modal-body,
.modal-footer {
  /* padding: 1.563rem 2.5rem; */
  padding: 1.375rem 2.5rem;
}

.modal-header {
  position: relative;
  background-color: var(--bs-black-500);
  align-items: start;
  justify-content: start;
  /* border-bottom: 0.125rem solid var(--bs-gray-200); */
}

.progress-line {
  border-radius: 0px 10px 0px 0px;
  background: #e0e0e0;
  background-color: #219ebc;
  margin-left: 2px;
  margin-right: 2px;
  height: 1px;
  width: 100%;
}

.progress-line .progress {
  position: relative;
  top: 0;
  width: 150px;
  height: 4px;
  border-radius: 0px 3px 3px 0px;
  background: linear-gradient(
    270deg,
    #219ebc 0%,
    #009970 50.73%,
    #bfcc2e 98.83%
  );
}

.modal-title {
  font-size: 18px;
  color: var(--bs-white);
  font-weight: 600;
}

.profile-modal .modal-title {
  font-size: 18px;
}

.modal-footer {
  justify-content: start;
  /* border-top: none; */
}

.modal-footer > * {
  margin-right: 1rem;
}

.modal-footer .btn {
  min-width: 5rem;
}

.modal-backdrop.show {
  opacity: 0.3;
}

.modal-dialog-scrollable .modal-body {
  min-height: calc(100vh - 18.75rem);
}

.btn-close {
  background: url(./images/close-icon.svg);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: none !important;
}

.body-modal .modal-dialog {
  max-width: 450px;
  /* height: 203px; */
}

.body-modal .modal-body {
  padding: 2rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
  }

  .profile-modal .modal-dialog {
    max-width: 576px;
  }
}

/* ********************************************************************************************
  Navbar
*********************************************************************************************** */

.navbar {
  /* padding: 0.875rem 0 1.125rem 0; */
  border-bottom: 0.063rem solid var(--bs-body-bg-color);
}

.navbar-brand {
  margin: 0;
  padding: 0;
}

.verticle-line {
  background: var(--bs-gray-400);
  width: 0.125rem;
  height: 3.5rem;
  margin: 0 1.563rem;
}

.navbar .search-box {
  margin: 0 1rem 0 3rem;
  position: relative;
}

.navbar .search-box img {
  position: absolute;
  top: 0.875rem;
  left: 0.875rem;
  height: 1rem;
}

.navbar .search-box input {
  min-width: 15.625rem;
  background-color: var(--bs-gray-200);
  border-radius: 0.188rem;
  border: 0;
  padding-left: 2.625rem;
}

.navbar .search-box input:focus {
  box-shadow: none;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  color: var(--bs-body-color);
  position: relative;
  font-size: 1rem;
}

.navbar-expand-lg .navbar-nav .nav-link:hover,
.navbar-expand-lg .navbar-nav .nav-link.active {
  color: var(--bs-primary);
}

.navbar-expand-lg .navbar-nav .nav-link.active::before {
  content: '';
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  border-style: solid;
  border-width: 0 1rem 0.313rem;
  border-color: var(--bs-primary);
  display: block;
  width: 100%;
  height: 0.188rem;
  z-index: 1;
  margin-left: 0;
}

.navbar-expand-lg .navbar-nav .dropdown .nav-link.active::before {
  display: none;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  top: 3.438rem;
  /* width: 12.5rem; */
  width: 240px;
  right: 0;
  left: auto !important;
  border: 0;
  border-radius: 0.188rem;
  animation-name: slideUpIn;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  margin: 0.313rem 0 0;
  box-shadow: 0 0 0.5rem rgba(var(--bs-black-rgb), 0.24);
}

.navbar-expand-lg .navbar-nav .drop-down.top-notification .dropdown-menu {
  width: 22rem;
}

.navbar-expand-lg
  .navbar-nav
  .drop-down.top-notification
  .dropdown-menu
  .nav-item {
  text-align: center;
  flex: 1 1 auto !important;
}

.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-item {
  /* padding: 0.813rem 1.5rem; */
  padding: 1rem 14px;
  color: var(--bs-black);
  font-size: 1rem;
}

.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-item:last-child {
  border-bottom: 0;
}

.navbar-expand-lg .dropdown .dropdown-toggle::after {
  display: none;
}

.dropdown-item.active,
.dropdown-item:active,
.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-item:hover {
  background: var(--bs-body-bg-color);
  color: var(--bs-black-300) !important;
}

.dropdown .dropdown-menu.show {
  border-radius: 8px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 8px 0px;
  border: 0px;
  padding: 6px 0 !important;
  left: auto !important;
  right: 0 !important;
  /* top: 8px !important; */
}

.dropdown .dropdown-item {
  padding: 11px 14px;
  font-size: 13px;
  color: var(--bs-black-300);
}

.dropdown-toggle::after {
  display: none;
}

.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-item img {
  margin-right: 0.875rem;
}

.navbar-expand-lg .navbar-nav .dropdown {
  display: flex;
  align-items: center;
}

.header-profile-img {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: 100%;
}

.question-dropdown {
  font-size: 1rem;
}

/* my profile */
.tab-light-border .nav {
  position: relative;
  line-height: 1;
}

.tab-light-border .nav::before {
  content: '';
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
}

.tab-light-border .nav-link {
  border-radius: 0;
  color: #636363;
  padding: 15px 0px;
  margin-right: 40px;
  font-weight: 400;
  font-size: 16px;
  border-bottom: 4px solid transparent;
  z-index: 99;
  display: flex;
  position: relative;
}

.tab-light-border .nav-link.active,
.tab-light-border .show > .nav-link {
  background: var(--bs-white-500);
  border-bottom: 3px solid var(--bs-primary);
  color: var(--bs-primary);
  font-weight: 600;
}

.tab-light-border .nav-link.active img {
  visibility: visible;
}

.tab-light-border .nav-link img {
  visibility: hidden;
}

.top-notification .tab-light-border .nav-link {
  padding: 10px 19px;
  color: #6d6d6d;
}

.top-notification .tab-light-border .nav-link.active {
  color: #000;
}

.rounded-box {
  width: 84px;
  height: 75px;
  background: #fff;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  display: flex;
}

.new-heading {
  color: #5e5e5e;
  font-weight: 400;
  line-height: 24px;
  font-size: 24px;
}

.min-w-100 {
  min-width: 100px !important;
}

.badge-info {
  background-color: var(--bs-primary-light) !important;
  color: var(--bs-primary);
}

.badge-primary {
  background: var(--bs-primary) !important;
  border: 0.063rem solid var(--bs-primary) !important;
}

.border-end {
  border-right: 1px solid #ededed !important;
}

.bottom-border {
  border-top: 1px solid #ededed;
}

.used-questionnaire-label {
  background: #219ebc;
  color: #ffffff;
}

.used-questionnaire-label-blank {
  background: #fff;
  color: #ffffff;
}

.popular {
  position: absolute;
  top: 6px;
  right: 12px;
}

.list-group-flush .card {
  height: 100%;
}

.edit-btn {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

/* fontsize */

.font-size-12 {
  font-size: 0.75rem !important;
}

.font-size-14 {
  font-size: 0.875rem !important;
}

.font-size-16 {
  font-size: 1rem;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 1.25rem !important;
}

.font-size-22 {
  font-size: 1.375rem;
}

.font-size-24 {
  font-size: 1.5rem;
}

.font-size-26 {
  font-size: 1.625rem;
}

.font-size-28 {
  font-size: 1.75rem;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-40 {
  font-size: 2.5rem;
}

.font-size-64 {
  font-size: 4rem;
}

.lh-21 {
  line-height: 1.313rem !important;
}

/* dashboard */
.body-bg-gray {
  background-color: var(--bs-body-bg-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chart-badge {
  width: 17px;
  height: 3px;
  margin-right: 10px;
}

.chart-badge.warning {
  background: #ff9b00;
}

.chart-badge.info {
  background: #4bac4f;
}

.text-body {
  color: var(--bs-body-color) !important;
}

.hr-dashboard .page-header .card {
  width: 185px;
  margin-right: 1.5rem;
  border-radius: 3px;
}

.hr-dashboard .page-header .card:last-child {
  margin-right: 0;
}

.hr-dashboard .page-header .card-body {
  padding: 7px 14px;
}

.hr-dashboard .page-header .card p {
  color: #6d6d6d;
}

.ms-12 {
  margin-left: 12px !important;
}

.table {
  --bs-table-bg: none;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.04);
  background: #fff;
  border-radius: 8px;
  overflow: hidden !important;
  margin: 0;
}

.hr-dashboard .page-content-card .card-header {
  padding: 9px 14px;
  border-bottom: 2px solid #f3f3f3;
}

.hr-dashboard .page-content-card .card-body {
  padding: 14px 14px 7px;
}

.hr-dashboard .custom-table .table {
  border-radius: 2px;
}

.hr-dashboard .custom-table thead {
  background: #f8f8f8;
  border-bottom: none;
  border-radius: 2px;
}

.hr-dashboard .custom-table thead th {
  padding: 6px 18px;
  color: var(--bs-black-400);
  font-weight: 400;
}

/* .custom-table.new-thead .table {
    border-Width : 0 !important;
} */
.table,
.table > :not(caption) > * > * {
  border-width: 0;
}

.hr-dashboard .custom-table tbody td {
  padding: 22px 18px;
  border-bottom: 1px solid #f1f1f1;
}

.hr-dashboard .custom-table .user-img {
  height: 18px;
  width: 18px;
  border-radius: 100%;
  margin-right: 5px;
}

.hr-dashboard .text-warning {
  color: #fbd257 !important;
}

.hr-dashboard .text-success {
  color: #4caf50 !important;
}

.hr-dashboard .page-content-card .card-header .small-text {
  color: #6d6d6d;
}

.w-150 {
  width: 150px;
}

.w-130 {
  width: 130px;
}

.hr-dashboard .form-select {
  font-size: 14px;
  color: #000000;
  padding: 0.375rem 0.75rem;
  border: 1px solid #cecece;
  box-shadow: none !important;
}

.slider-navigation-button {
  height: 2.063rem;
  width: 2.063rem;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.14);
}

.page-header .bg-white {
  height: 38px !important;
  border-radius: 3px;
}

.bg-white {
  background-color: #ffffff;
}

.slider-card {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  padding: 14px;
}

.slider-card-image {
  height: 4.25rem;
  width: 8.125rem;
}

.slider-card-label {
  border-bottom-right-radius: 5px;
  padding: 0.313rem 0.635rem;
}

.slider-card-label-questionnaire {
  background-color: rgba(0, 0, 0, 0.04);
  color: #929292;
}

.slider-card-label-exam {
  background-color: rgba(76, 175, 80, 0.2);
  color: #4caf50;
}

.max-width-400 {
  max-width: 400px;
}

.max-width-600 {
  max-width: 600px;
  margin-top: 75px;
}

.max-width-1000 {
  max-width: 1000px;
}

.lato-text {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.p-14 {
  padding: 0.875rem;
}

.page-card {
  border: 0;
  border-radius: 0;
}

.card-equal-height {
  height: 100%;
}

.card-header {
  background-color: var(--bs-white);
  padding: 14px 22px;
  border-bottom: 1px solid #ededed;
}

.card-header.light-border {
  border-bottom: 1px solid #f8f8f8;
}

.card-header.new-border {
  border-bottom: 1px solid #ededed;
}

.notification .page-card .card-header {
  padding: 20px 37px 25px 37px;
}

.page-card .card-body {
  padding: 22px !important;
}

.h-20 {
  height: 1.25rem;
}

.exam-count-legend li {
  font-size: 15px;
}

.exam-count-legend li:not(:last-child) {
  margin-bottom: 16px;
}

.text-black-100 {
  color: var(--bs-black-100) !important;
}

.round-circle {
  height: 15px;
  width: 15px;
  display: block;
  border-radius: 100%;
  margin-right: 10px;
  background-color: #ccc;
}

.card-scroll {
  max-height: 440px;
  overflow-y: auto;
}

.custom-text-clr {
  color: #6d6d6d !important;
}

/*   Modal  */
.card,
.modal-footer,
.modal-header {
  /* border-color: #ebebeb; */
  border-color: #ededed;
}

/* scroolbar */

::-webkit-scrollbar {
  height: 5px;
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.table.table-shadow {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.12);
}

.table.table-shadow tr th {
  background-color: #f2f2f2;
}

.table,
.table > :not(caption) > * > * {
  padding: 0.938rem;
  border-color: #e1e1e1;
}

.custom-table .MuiPaper-root {
  box-shadow: none;
}

/* .card:hover {
  transform: scale(1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: 0.5s;
} */

.in-draft-carousel {
  margin-left: -1rem;
  margin-right: -1rem;
}

/* backbtn:- */

.back-btn {
  font-size: 14px;
  font-weight: 400;
  color: var(--bs-gray-700);
}

.resend-text span {
  font-weight: 600;
  opacity: 0.4;
}

/* .sidebar {
  background-color: var(--bs-black-500);
    min-width: 64px;
    position: fixed;
    position: fixed;
    top: 83px;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    padding-top: 0;
    z-index: 1041;
    transition: transform .3s ease-in-out;
    padding-top: 32px;
} */

.siderbar-menu {
  background-color: #023047;
  width: 250px;
  position: fixed;
  top: 66px;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 1041;
  transition: transform 0.3s ease-in-out;
  padding-top: 32px;
}

.siderbar-menu a.active {
  max-width: 230px !important;
  background: #15728d !important;
}

/* .siderbar-menu a:hover {
  width: 230px;
  background-color: #073e5a;
} */
/* ********************************************************************************************
  Vertical Tab
*********************************************************************************************** */
.title-border {
  border-bottom: 1px solid rgba(49, 57, 60, 0.1);
  margin-right: 65px;
}

.left-side {
  background-color: #f9f9f9;
}

.verical-tab .nav-pills {
  /* padding-top: 40px;
  padding-left: 50px;
  width: 407px;
  background-color: #f9f9f9;
  height: 100vh;
  position: fixed; */
}

.verical-tab .nav-pills .nav-link {
  color: var(--bs-black-400);
  font-size: 1rem;
  font-weight: 400;
  padding: 12px 20px;
  margin-bottom: 10px;
  max-width: 100%;
}

.verical-tab .nav-pills .nav-link:hover {
  background-color: #f2f2f2;
}

.verical-tab .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  border-radius: 8px;
  background-color: #e3f0f3;
  color: #219ebc;
  font-weight: 600;
}

.verical-tab .tab-content {
  /* padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 470px;
  padding-right: 52px;
  flex: 1 1; */
}

.warning-box {
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  background: #fff5cc;
}

/* ********************************************************************************************
  Bank Details
*********************************************************************************************** */
.main-box {
  width: 351px;
  text-align: center;
  margin-left: 135px;
  margin-top: 43px;
}

.blank-box {
  border-radius: 8px;
  background: #eee;
  width: 351px;
  height: 259px;
  text-align: center;
  line-height: 259px;
}

.blank-box span {
  color: #979797;
  font-weight: 600;
}

.bank-details {
  width: 588px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 18px 24px;
  margin-top: 10px;
}

/* ********************************************************************************************
  Profile Flow
*********************************************************************************************** */
.main-section .main-body {
  margin-top: -45px;
}

.edit-icon {
  position: absolute;
  top: 30px;
  right: 52px;
  cursor: pointer;
}

.user-profile {
  position: relative;
  margin-right: 75px;
}

.user-profile .user-edit-icon {
  position: absolute;
  bottom: -3px;
  right: 8px;
}

.right-side-box {
  display: flex;
  align-items: center;
  padding: 18px 16px;
  border-radius: 10px;
  background: #f7f7f7;
  margin-left: auto;
  margin-right: 52px;
  margin-top: 64px;
}

.right-side-box .center-border {
  width: 1px;
  height: 60px;
  margin: 0 28px;
  background: #dfdfdf;
}

.slider-box {
  width: 250px;
  display: inline-block;
  border-radius: 8px;
  background: #f7f7f7;
  padding: 1rem;
  margin-right: 24px;
  margin-left: 24px;
}

.profile-tab .nav-link {
  font-size: 1rem;
  color: var(--bs-gray-700);
  font-weight: normal;
}

.profile-tab .nav-link.active,
.profile-tab .show > .nav-link {
  border-bottom: 2px solid var(--bs-primary);
  color: var(--bs-primary);
}

.tab-line {
  margin-bottom: 30px;
  margin-top: 12px;
  background: #ededed;
}

.photo-uploader {
  width: 100%;
  min-height: 256px;
  border-radius: 10px;
  border: 1px dashed var(--bs-gray-100);
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.upload-employee {
  border: 1px dashed #d3d3d3;
  background: #fafafa;
  padding: 24px;
  border-radius: 10px;
}

.upload-employee .card {
  border: 1px solid #e6e6e6 !important;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.03) !important;
}

.experience-list {
  padding: 17px 33px;
  border-radius: 10px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  margin: 0 35px;
  cursor: pointer;
}

.selected-expertise {
  border-radius: 8px;
  padding: 5px 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  background: #f5f5f5;
  margin-bottom: 10px;
  vertical-align: middle;
}

.new-expertise {
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}

.shadow-card-box .card .test-price {
  color: var(--bs-black-100);
}

.shadow-card-box .card {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  border: 0;
  margin-bottom: 1.5rem;
}

.shadow-card-box .card .rounded-top {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.shadow-card-box .card .card-body {
  /* padding: 1rem !important; */
}

.shadow-card-box .card .card-title {
  color: var(--bs-black-100);
}

.shadow-card-box .card .ratings .rate {
  color: var(--bs-black-100);
}

.profile-carousel .start-0 {
  left: 10px !important;
}

.profile-carousel .end-0 {
  right: 10px !important;
}

/* ********************************************************************************************
  Order Flow
*********************************************************************************************** */

.filter-btn {
  display: flex;
  align-items: center;
  padding: 8px 14px;
  border-radius: 8px;
  color: var(--bs-black-500);
  font-weight: 600;
  border: 1px solid #a7a5a5;
  cursor: pointer;
}

.order-status {
  border-radius: 44px;
  background: #f2f2f2;
  padding: 4px 8px;
  margin-right: 1rem;
}

/* 
.table-responsive {
  border-radius: 8px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
}

.table {
  margin: 0;
}

.table,
.table > :not(caption) > * > * {
  padding: 0.938rem;
  border-color: #e1e6ef;
  vertical-align: middle;
}

.table thead tr {
  border: 0 !important;
}

.table tbody td {
  color: var(--bs-black-400);
}

.table-secondary th {
  background-color: #f0f0f0 !important;
}

.table-light th {
  background-color: #f8f8f8 !important;
}*/

.table-accordian-light th {
  background-color: #f9f9f9 !important;
  border-bottom: 1px solid #e1e6ef;
}

.table .accordian-table-body td {
  color: var(--bs-gray-700);
}

.table .accordian-table-body .icon-group {
  display: none;
}

.table .accordian-table-body .icon-group img {
  height: 17px;
}

.table .accordian-table-body tr:hover .icon-group {
  display: block;
}

/* .table .form-check-input {
  width: 1.25rem;
  height: 1.25rem;
}  */

.badge {
  border-radius: 5px;
  padding: 6px 7px;
  font-size: 14px;
  font-weight: 500;
}

.gray-badge {
  border-radius: 10px;
  background: rgba(109, 109, 109, 0.1);
  color: #6d6d6d;
}

.badge-warning {
  background-color: rgba(239, 152, 48, 0.1) !important;
  color: #ef9830;
}

.badge-danger {
  background-color: rgba(242, 74, 74, 0.1) !important;
  color: #f24a4a;
}

.search-box {
  max-width: 289px;
}

.search-box .form-control {
  padding: 0.5rem 0.2rem;
  border-left: 0;
  box-shadow: none;
}

.search-box .input-group-text {
  background: transparent;
  border-right: 0;
}

/* ********************************************************************************************
  Analytics Flow
*********************************************************************************************** */

.analytics-table .table-responsive {
  box-shadow: none;
}

.analytics-table .table-responsive th {
  padding: 0.5rem 0.938rem;
  color: var(--bs-gray-700);
}

/* .shadow-card-box.analytics-card .card {
  height: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.14);
} */

/* ********************************************************************************************
  Question paper library
*********************************************************************************************** */

.bg-green-sold {
  border-radius: 3px 3px 10px 3px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(152, 192, 38, 0.12);
  padding: 5px 10px;
}

.bg-green-sold .sold-text {
  color: #98c026;
  font-size: 1rem;
  font-weight: 600;
  vertical-align: middle;
}

/* ********************************************************************************************
  Question Paper - View details 
*********************************************************************************************** */

.gray-box {
  border-radius: 5px;
  background: #f8f8f8;
  padding: 28px 0px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.gray-box .saprate-border {
  background-color: var(--bs-gray-500);
  width: 1px;
  height: 63px;
}

.Inactive-answer {
  width: 34px;
  height: 34px;
  display: inline-block;
  background-color: #f2f0f0;
  color: var(--bs-gray-700);
  margin-right: 10px;
  border-radius: 34px;
  text-align: center;
  line-height: 34px;
  font-weight: 600;
}

.Active-answer {
  width: 34px;
  height: 34px;
  display: inline-block;
  background-color: #009970;
  color: var(--bs-white);
  margin-right: 10px;
  border-radius: 34px;
  text-align: center;
  line-height: 34px;
  font-weight: 600;
}

.collapse-row[aria-expanded='true'] .rotate-img {
  transform: rotate(180deg);
  transition: all 0.3s linear;
}

.collapse-row[aria-expanded='true'] {
  border-bottom: 1px solid #e1e6ef !important;
  background: #f4fafc;
}

.review-border {
  border-bottom: 1px solid var(--bs-gray-400);
}

/* ********************************************************************************************
  Side Modal 
*********************************************************************************************** */

.sideModal .modal-dialog {
  margin: 0;
  width: 500px;
  margin-left: auto;
  height: 100%;
  transform: translateX(100%) !important;
}

.sideModal.show .modal-dialog {
  transform: none !important;
}

.sideModal .modal-dialog .modal-content {
  border-radius: 0;
  min-height: 100%;
}

.sideModal .modal-header {
  border-radius: 0;
  padding: 0;
  background: transparent;
}

.sideModal .modal-header .btn-close {
  position: absolute;
  left: -45px;
  top: 12px;
}

.sideModal .modal-footer {
  padding: 1.063rem 2.188rem;
}

.sideModal .modal-body {
  padding: 1.875rem 2.188rem;
}

/* ********************************************************************************************
  Tooltip 
************************************************************************************/
.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
  cursor: pointer;
  z-index: 10;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 70px;
  background-color: var(--bs-white);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
  color: var(--bs-black-300);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  right: -24px;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* ********************************************************************************************
  Pagination Code
*********************************************************************************************** */
.custom-pagination {
  margin-top: 60px;
}

.custom-pagination a:hover {
  border: 1px solid #f1f1f1;
  color: var(--bs-body-color) !important;
}

.custom-pagination .page-item:first-child a,
.custom-pagination .page-item:last-child a {
  border: 0;
  font-size: 30px;
}

.custom-pagination .page-link {
  color: var(--bs-body-color);
  border: 1px solid #f1f1f1;
  background-color: transparent;
  width: 44px;
  margin-right: 10px;
  box-shadow: none;
  height: 44px;
  line-height: 30px;
  border-radius: 44px;
  text-align: center;
  font-size: 1rem;
}

.custom-pagination .page-item.active .page-link {
  background-color: var(--bs-primary) !important;
  border: 1px solid var(--bs-primary);
  color: var(--bs-white);
}

/* ********************************************************************************************
  Create Question Paper Flow
*********************************************************************************************** */

.progress-header {
  background: #31393c;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  padding: 22px 70px;
  display: flex;
  align-items: center;
}

.progress-header a:hover {
  color: #fff !important;
  opacity: 0.9;
}

.progress-header-new {
  background: #009970;
}

.question-img {
  width: auto;
  height: auto;
  margin-bottom: 24px;
  padding: 30px 0;
  text-align: center;
  line-height: normal;
  border-radius: 10px;
  background: #eee;
  cursor: pointer;
}

.question-img small {
  font-size: 12px;
  color: #979797;
}

.question-img.active {
  border: 2px solid var(--bs-primary);
  background: rgb(33 158 188 / 10%);
}

.uploaded-file {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 15px 24px;
}

.uploaded-file .loading-file {
  position: absolute;
  top: 0;
  left: 0;
  width: 227px;
  border-radius: 10px 0px 10px 0px;
  background: #fffdf5;
  padding: 15px 24px;
  z-index: -1;
  bottom: 0;
}

.preview-qus-card {
  border-radius: 20px;
  border: 1px solid rgba(33, 158, 188, 0.2);
}

.add-image-uploader {
  border: 1px dashed var(--bs-primary);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edit-check {
  left: -33px;
  top: 50%;
}

.edit-check .form-check-input {
  border-color: var(--bs-gray-700) !important;
  width: 22px;
  height: 22px;
}

.edit-check .form-check-input:checked[type='radio'] {
  border: 5px solid var(--bs-primary) !important;
  background: var(--bs-primary);
}

.edit-check .form-check-input:checked[type='radio']::before {
  background-color: var(--bs-white) !important;
}

/* accordian  and help page */

.accordion-item {
  border: 0 !important;
  border-bottom: 1px solid #e8e8e8 !important;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.accordion-button:not(.collapsed) {
  color: #121212;
  background-color: #fbd25714;
  box-shadow: none;
}
.accordion-body {
  /* background-color: #fbd25714; */
}
button:focus:not(:focus-visible) {
  /* outline: 0; */
}
.accordion-button:focus {
  outline: 0;
  box-shadow: none;
}
.accordion-button {
  font-weight: 600;
}
.fa-angle-right {
  color: #cecece !important;
}

.accordion-button::after {
  content: '';
  background-image: url(../assets/images/help_plus.svg);

  background-repeat: no-repeat;
  background-size: 1rem;
}
.accordion-button:not(.collapsed)::after {
  background-image: url(../assets/images/help_minus.svg);
}

.card-footer {
  padding: 1rem;
  background-color: #ffffff !important;
  /* border-top: 0 !important; */
  border-top-color: #eaebeb;
}

.card-footer:last-child {
  border-radius: 0 0 10px 10px;
}

.help-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #4caf5024;
  padding: 34px 22px;
  border-radius: 10px;
}

.help-faq .card-header {
  padding: 0 !important;
}

.help-faq.shadow-card-box .card .card-body {
  padding: 0 !important;
}

.help-faq .card-footer {
  padding: 0 !important;
}

@media only screen and (max-width: 767px) {
  .verical-tab .nav-pills {
    visibility: hidden;
  }

  .verical-tab .tab-content {
    padding-left: 50px;
  }
}

/* Login Logo:- */

.registration-screen {
  position: absolute;
  top: 41px;
  z-index: -1;
}

.registration-screen h1 {
  font-size: 46px;
  font-weight: 700;
}

.registration-screen h5 {
  opacity: 0.7;
  line-height: 30px;
  font-weight: 500;
}

.progress-view small {
  color: #636363;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-wrap: nowrap;
}

.steps-items .active-steps {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  margin: auto;
  margin-bottom: 8px;
  background-color: #219ebc;
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  line-height: 25px;
}

.steps-items .inactive-steps {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  margin: auto;
  margin-bottom: 8px;
  border: 1px solid #219ebc;
  text-align: center;
  color: #219ebc;
  font-size: 12px;
  font-weight: bold;
  line-height: 25px;
}

.change-password-card {
  width: 503px;
  margin: 30px auto;
}

.change-password-card .card .card-body {
  padding: 30px 60px !important;
}

.change-password-card .card .card-footer {
  border-top: 1px solid #eaebeb !important;
  padding: 16px 60px !important;
}

.insight-uploader {
  border-radius: 5px;
  background-color: #f5f5f5;
  padding: 6px;
  height: 131px;
  display: flex;
  justify-content: center;
}

.insight-uploader .insight-inner {
  border-radius: 5px;
  border: 1px dashed #6d6d6d;
  padding: 30px 0;
  display: flex;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.header-gradient-link {
  border-radius: 40px;
  background: linear-gradient(86deg, #ffe69b 9.17%, #ffcd36 97.49%);
  padding: 6px 13px;
  height: 36px;
  color: var(--bs-black-500);
  display: flex;
  align-items: center;
}

.header-gradient-link:hover {
  color: var(--bs-black-500) !important;
}

.sidebar-accordian .accordion {
  --bs-accordion-bg: transparent !important;
}

.sidebar-accordian .accordion .accordion-item {
  border: 0 !important;
}

.sidebar-accordian .accordion .accordion-button {
  background-color: transparent;
  border: 0;
  color: #fff;
  box-shadow: none;
  padding-bottom: 1rem;
  padding-left: 0;
  padding-right: 0;
}

.sidebar-accordian .accordion .accordion-body {
  padding-left: 30px;
}

.sidebar-accordian .accordion .accordion-collapse {
  position: relative;
}

.sidebar-accordian .accordion .accordion-collapse::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 1px;
  background-color: #fff;
  opacity: 0.4;
  height: 100%;
  left: 10px;
  bottom: 0px;
  top: -17px;
}

.sidebar-accordian .accordion .accordion-button::after {
  background-image: url(../assets/images/down-collapse-icon.svg);
}

.sidebar-accordian .accordion .primary-active-item {
  border-radius: 5px;
  padding: 7px 12px;
  width: 100%;
  color: #fff;
  /* font-weight: 600; */
  text-wrap: nowrap;
  /* background: #15728d; */
  /* display: inline-block; */
  position: relative;
}

.primary-inactive-item {
  display: block;
  /* background: transparent; */
  font-weight: 400;
  /* opacity: 0.7; */
}

.sidebar-accordian .accordion .primary-active-item:hover {
  color: #fff !important;
}

.sidebar-accordian .accordion .primary-active-item::before {
  content: '';
  position: absolute;
  background-color: #fff;
  opacity: 0.4;
  width: 10px;
  height: 1px;
  left: -19px;
  bottom: 0;
  top: 17px;
}

.bottom-link {
  width: 100%;
  padding-top: 6px;
  border-top: 1px solid #212121;
}

/* Style the tabs */
.tabs {
  display: flex;
  flex-direction: column;
}

.tab {
  padding: 10px;
  cursor: pointer;
}

.tab.active {
  font-weight: bold;
}

/* Style the progress steps content */
.progress-steps {
  padding: 10px;
}

.step {
  display: none;
}

.step.active {
  display: block;
}

.vertical-steps span {
  margin-top: -1.5px;
}

.vertical-steps .active-steps .steps-circle {
  border: 3px solid #219ebc;
}

.vertical-steps .active-steps .bar-vertical-line {
  background: #219ebc;
}

.vertical-steps .active-steps span {
  color: #219ebc;
  font-weight: 600 !important;
}

.steps-circle {
  width: 20px;
  height: 20px;
  border: 3px solid #d3d3d3;
  border-radius: 20px;
}

.bar-vertical-line {
  margin: auto;
  width: 2px;
  height: 28px;
  background-color: #d3d3d3;
}

.table thead th {
  background: #f3f3f3;
  border: 0;
  text-wrap: nowrap;
  padding: 0.938rem 24px;
}

.table tbody tr td {
  border-top: 0;
  vertical-align: middle;
  border-bottom: 1px solid #e6e6e6;
  text-wrap: nowrap;
  padding: 24px;
  color: #525252 !important;
}

.table tbody tr td:first-child {
  color: #121212 !important;
}

/* Table Filter Dropdown */

.table-filter-dropdown .dropdown-toggle {
  background: #fb8500;
  border-radius: 8px;
  padding: 8px 16px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.table-filter-dropdown .dropdown-menu.show {
  top: 7px !important;
  width: 184px;
}

.table-filter-dropdown .dropdown-item {
  font-size: 16px;
  padding: 13px 18px;
}

.table-filter-dropdown .dropdown-item:hover {
  color: #212121 !important;
}

.social-box {
  padding: 8px;
  align-items: center;
  border-radius: 10px;
  background: #f5f5f5;
}

.assign-client-space {
  border-bottom: 1px solid #ededed;
  padding-bottom: 30px;
  margin-bottom: 35px;
}

.assign-client-modal {
  border-right: 1px solid #ededed;
}

/* Company List CSS */

.badge-plan {
  display: flex;
  color: #ef9830;
  font-size: 12px;
  border-radius: 10px;
  background: rgba(255, 206, 54, 0.2);
  padding: 2px 8px;
}
.badge-blue {
  border-radius: 100px;
  background: #e9f5f8;
  padding: 2px 10px 3px 10px;
  font-size: 12px;
  margin-left: 4px;
  color: var(--bs-primary);
}

.company-list-card .card {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}

.company-list-card .card a:hover {
  color: #212121 !important;
  text-decoration: underline;
}

.saprater {
  background: #e6e6e6;
  width: 100%;
  height: 1px;
}

.vetical-saprater {
  width: 1px;
  height: 30px;
  background: #e6e6e6;
}

.company-list-card .form-check .form-check-label {
  color: #636363;
}

.filter-list .dropdown-toggle {
  background: transparent !important;
  border: 0 !important;
  color: #212121 !important;
  font-weight: 400 !important;
}

/* Company Detail CSS */

.require-skill {
  display: inline-block;
  padding: 10px 12px;
  border-radius: 50px;
  margin-right: 14px;
  background: rgba(33, 158, 188, 0.1);
}

ul li {
  font-size: 1rem;
}

.border-card {
  box-shadow: none;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  padding: 23px 30px;
}

.border-card .saprater {
  width: 1px;
  height: 23px;
}

.active-category {
  border-radius: 16px;
  border: 1px solid var(--bs-primary);
  background-color: var(--bs-primary);
  padding: 6px;
  color: #fff;
  text-wrap: nowrap;
  margin-right: 10px;
  display: inline-block;
}

.Inactive {
  border-radius: 16px;
  border: 1px solid #a8a8a8;
  padding: 6px;
  text-wrap: nowrap;
  color: #a8a8a8;
  margin-right: 10px;
  display: inline-block;
}

.request-empale-modal .modal-content {
  max-width: 500px;
  margin: auto;
  padding: 10px 20px;
}

.filter-bar {
  border-left: 1px solid #d3d3d3 !important;
  box-shadow: none;
  border-radius: 0 !important;
}

.connection-request-list::-webkit-scrollbar {
  width: 4px;
  height: 150px;
  border-radius: 4px;
  background: #d3d3d3;
}
.connection-request-list {
  height: calc(100vh - 350px);
  overflow-y: scroll;
}

.company-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 14px;
}

.company-list .selected-company {
  border-radius: 17px;
  background: rgba(230, 230, 230, 0.5);
  padding: 8px 14px 9px 14px;
  color: #212121;
  font-size: 12px;
  margin-right: 8px;
  display: flex;
}

.gradient-bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 56px;
  padding-bottom: 27px;
  background: linear-gradient(180deg, rgba(255, 246, 220, 0) 0%, #fff1c7 50%);
}

.gradient-bg p {
  color: #ef9830;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

/*Job CSS*/

.tab-accordian .accordion {
  --bs-accordion-bg: transparent;
}

.tab-accordian .accordion-item {
  border: 0;
}

.tab-accordian .accordion-button {
  box-shadow: none;
  background-color: transparent;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 24px;
  padding-right: 0;
}

.tab-accordian .accordion-button::after {
  margin-left: 10px;
  width: 24px;
  height: 24px;
}

.pdf-card.card {
  border: 1px solid #f24a4a !important;
}

.selection-check .form-check {
  border-radius: 24px;
  background: rgba(251, 133, 0, 0.12);
  padding: 5px 8px 7px 7px;
  display: flex;
  align-items: center;
}

.selection-check .inactive {
  background-color: white;
  border-radius: 24px;
  border: 1px solid #d3d3d3;
  padding: 5px 8px 7px 7px;
  display: flex;
  align-items: center;
}

.selection-check .form-check .form-check-input {
  margin-left: 0;
}

.selection-check .form-check-input[type='radio'] {
  border-color: #a8a8a8;
  border-width: 2px;
}

.selection-check .form-check-input:checked[type='radio'] {
  background-color: #fb8500;
  border: 0;
}

.selection-check .form-check-input:checked[type='radio']::before {
  content: '';
  width: 8px;
  height: 8px;
  background-color: white;
}

.match-badge {
  border-radius: 6px;
  background: #27ae60;
  padding: 3px 10px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
}

.match-badge.danger {
  background: var(--Error, #f24a4a);
}

.match-badge.warning {
  background: #e7b725;
}

.jd-image-height {
  height: 50px;
  width: 50px;
}

.dashboard-dropdown .btn-outline-dark {
  border: 1px solid #d3d3d3 !important;
  background: #fff !important;
  color: var(--bs-black) !important;
}

.dashboard-table .table thead th {
  color: #636363;
  font-weight: 400;
}

.dashboard-table .table tbody td {
  color: var(--bs-black) !important;
}

.light-primary-box {
  border-radius: 8px;
  color: var(--bs-primary);
  font-weight: 600;
  display: flex;
  background: rgba(33, 158, 188, 0.1);
  padding: 7px 7px 7px 5px;
  align-items: center;
}

.filter-accordian .accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
  border-bottom: 1px solid #e8e8e8 !important;
}
.filter-accordian .accordion-item {
  border: 0 !important;
  border-bottom: 1px solid #ededed !important;
}
.filter-accordian .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-top: 1px solid #ededed !important;
}
.filter-accordian .accordion-button:not(.collapsed) {
  color: #121212;
  background-color: #fff;
  box-shadow: none;
}
.filter-accordian .accordion-body {
  background-color: #fff;
  padding-top: 0;
}
.filter-accordian button:focus:not(:focus-visible) {
  outline: 0;
}
.filter-accordian .accordion-button:focus {
  outline: 0;
  box-shadow: none;
}
.filter-accordian .accordion-button {
  font-weight: 600;
  padding-left: 0;
}

.question-card .card {
  border-radius: 10px;
  border: 1px solid#D3D3D3;
  background: #fff;
  overflow: hidden;
  height: 100%;
}

.question-card .card:hover {
  box-shadow: 0px 0px 13.263px 0px rgba(0, 0, 0, 0.1),
    0px 0px 6.631px 0px rgba(0, 0, 0, 0.14);
  cursor: pointer;
}

.question-card .card .examinor-profile {
  margin-top: -36px;
  margin-bottom: 10px;
  z-index: 1;
  position: relative;
}

.store-card .card .examinor-profile {
  margin-top: -36px;
  margin-bottom: 10px;
  z-index: 1;
  position: relative;
}

.question-card .bookmark-btn {
  position: absolute;
  top: 13px;
  right: 10px;
}

.question-card .exam-paper-list {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.subject {
  border-radius: 10px;
  border: 0.947px solid #e6e6e6;
  padding: 3.789px 9.473px;
  background: #fff;
  margin-right: 10px;
  color: #424242;
  box-shadow: 0px 0px 6.631px 0px rgba(0, 0, 0, 0.14);
}

.nickname-circle {
  background: #212121;
  width: 44px;
  height: 44px;
  border-radius: 44px;
  text-align: center;
  line-height: 44px;
  font-size: 15px;
  color: #fff;
}

#pointer {
  width: 80px;
  font-weight: 700;
  height: 28px;
  color: #fff;
  position: relative;
  background: #f24a4a;
  text-align: center;
  line-height: 28px;
}

#pointer:before {
  content: '';
  position: absolute;
  right: -12px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 12px solid #f24a4a;
  border-top: 15px solid transparent;
  border-bottom: 13px solid transparent;
}

.cart-box {
  position: relative;
  z-index: 1000;
}

.checkout {
  position: relative;
}
.checkout::before {
  content: '';
  border-radius: 10px;
  position: absolute;
  opacity: 0.06;
  background: #fb8500;
  top: 0;
  width: 100%;
  height: 100%;
}

.list-group-item {
  border-color: #e6e6e6;
  padding: 1rem;
}

.avatar-custom-margin {
  margin-right: 2px;
}
/* Toggle Switch */

.form-check.form-switch .form-check-input {
  width: 29px;
  height: 17px;
  border-radius: 16px !important;
  border-color: #d3d3d3;
}

.form-check.form-switch .form-check-input:checked[type='checkbox'] {
  border: 0;
}

.custom-progress {
  height: 0.8rem !important;
  border-radius: 50px;
}

/* Answer analysis side panel*/
.answer-grid {
  position: sticky;
  top: 75px;
  height: 100%;
}
.question-list-card {
  border: 1px solid #cecece !important;
  border-radius: 3px !important;
  margin-bottom: 1.5rem !important;
}
.question-round-box {
  max-width: 322px;
  margin: 0 auto;
  max-height: calc(100vh - 391px);
  overflow-y: auto;
}
.question-round-box .box,
.legend-box .box {
  height: 42px;
  width: 42px;
  line-height: 40px;
  display: block;
  border-radius: 100%;
  margin: 0 6px 20px;
  text-align: center;
  color: #a7a5a5;
  font-weight: 600;
  background-color: #ededed;
  border: 1px solid #ededed;
}
.question-round-box .box.green,
.legend-box .box.green {
  background-color: rgba(76, 175, 80, 0.3);
  border: 1px solid #4caf50;
  color: var(--bs-black-100);
}
.question-round-box .box.danger,
.legend-box .box.danger {
  background-color: rgba(242, 74, 74, 0.3);
  border: 1px solid #f24a4a;
  color: var(--bs-black-100);
}
.question-round-box .box.warning,
.legend-box .box.warning {
  background-color: rgba(251, 210, 87, 0.3);
  border: 1px solid #fbd257;
  color: var(--bs-black-100);
}
.question-round-box .box.primary-easy,
.legend-box .box.primary-easy {
  background-color: #e0ecfc;
  border: 1px solid #2f80ed;
  color: var(--bs-black-100);
}
.question-round-box .box.primary,
.legend-box .box.primary {
  background-color: var(--bs-primary);
  border: 1px solid var(--bs-primary);
  color: var(--bs-white);
}
.legend-box .box {
  height: 16px;
  width: 16px;
  min-width: 16px;
  min-height: 16px;
  margin: 0 8px 0 0;
}
.legend-box.page-box {
  border-top: 1px solid #cecece !important;
  padding-top: 20px;
}
.legend-box li {
  font-size: 14px;
  margin-bottom: 1rem;
}
.legend-box li:last-child {
  margin-bottom: 0;
}
.primary-box {
  font-size: 14px;
  padding: 4px 18px;
  background-color: rgba(188, 226, 235, 0.5);
  width: 62px;
  min-width: 62px;
  text-align: center;
}

/*  Answer analysis Question*/
.question-preview {
  height: 80px !important;
  width: 80px !important;
  min-width: 80px;
  min-height: 80px;
  /* -webkit-flex-grow: 0 !important; */
  cursor: zoom-in;
  margin-left: 1.5rem;
  background-size: cover;
  margin-bottom: 0.5rem;
}
.image-preview-big {
  height: 80px !important;
  width: 80px !important;
  min-width: 80px;
  min-height: 80px;
  /* -webkit-flex-grow: 0 !important; */
  cursor: zoom-in;
}
.question-pre-tag pre {
  white-space: normal !important;
}

/* React select */

.react-select-container .react-select__control {
  min-height: 45px;
  border-radius: 8px;
  border-color: var(--bs-border-color);
  background-color: #fff;
}

.react-select-container
  .react-select__control.react-select__control--is-focused {
  box-shadow: 0 0 5px 0px rgb(0 0 0 / 25%);
  border-color: var(--bs-border-color);
  background-color: var(--bs-white);
}

.react-select-container .react-select__menu {
  box-shadow: 0 0 0 0px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
  border-radius: 3px;
  margin-top: 5px;
}

.react-select-container .react-select__indicator {
  color: #323232;
}

.react-select-container .react-select__placeholder {
  color: #a7a5a5;
}

.react-select-container .react-select__option {
  color: #000;
  padding: 10px 12px;
}

.react-select-container .react-select__option.css-1n7v3ny-option:active,
.react-select-container .react-select__option.css-yt9ioa-option:active,
.react-select-container .react-select__option.css-9gakcf-option,
.react-select-container .react-select__option.css-1n7v3ny-option {
  background-color: #f4f4f4;
  color: #000;
  border-color: #fff;
}

.underLineText:hover {
  text-decoration: underline;
}

.fileinput-container {
  position: relative;
  display: inline-block;
}

.fileinput-container input[type='file'] {
  cursor: pointer !important;
  font-size: 0;
}
.upload-border {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  border-width: 2px;
  border-radius: 3px;
  border-color: #a6a6a6;
  border-style: dashed;
  background: #f9f9f9;
  outline: none;
  transition: border 0.24s ease-in-out;
  min-height: 350px;
}

.blue-input {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 23px;
  cursor: pointer;
  opacity: 0;
  direction: ltr;
}

.upload-error {
  border-color: #f24a4a;
  background: #ffffff;
}

.upload-error-text {
  color: #f24a4a;
  font-weight: 700;
  font-size: 0.875rem;
}

.formik-error {
  color: #d32f2f;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

/* Job Description */

.assign-recruiter-image {
  max-height: 50px;
}

.custom-margin-bottom-date-time {
  margin-bottom: 2.3rem;
}

.custom-margin-right-date-time {
  margin-right: 2.3rem;
}
.candidate-tab-table th:nth-last-child(2) {
  text-align: center;
}
.raunr-alphabet-1 {
  width: 18px;
  min-width: 18px;
  max-width: 18px;
  height: 18px;
  border-radius: 100px;
  background-color: #828282;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
}

.raunr-alphabet-40 {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: #828282;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
}

.candidate-accordion .accordion-button:not(.collapsed) {
  background-color: #fafafa;
}

.candidate-accordion .accordion-header .accordion-button {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.skills-tag-name .tag-name {
  background: #f5f5f5;
  padding: 4px 10px 4px 10px;
  gap: 6px;
  border-radius: 8px;
  opacity: 0px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  display: inline-block;
  color: #121212;
}

.skills-tag-name .tag-name a {
  color: #121212;
  display: flex;
  align-items: center;
}

.skills-tag-name .tag-name a i {
  margin-left: 4px;
}

.icon-34-btn {
  background: #e9f5f8;
  width: 34px;
  height: 34px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.min-h-55 {
  min-height: 55px;
  min-width: 75px;
}

.store-card .card {
  box-shadow: 0px 0px 7px 0px #00000024;
  height: 100%;
}

.store-card .card-img {
  width: 100%;
  height: 134px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.store-card .card-img-list {
  width: 100%;
  height: 151px;
}

.store-card .card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s linear;
}

.questionnaire-subject {
  border: 1px solid #cecece;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 0.25rem 0.625rem;
}

.bestseller-badge {
  position: absolute;
  top: 10px;
  left: -10px;
  background-color: #f24a4a;
  color: white;
  padding: 4px 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  z-index: 10;
}

.bestseller-badge::before {
  content: '';
  position: absolute;
  border-radius: 2px;
  right: -28px;
  top: 50%;
  margin-top: -15px;
  border-width: 15px;
  border-style: solid;
  border-color: transparent transparent transparent #f24a4a;
}

.bestseller-badge::after {
  content: '';
  position: absolute;
  bottom: -9px;
  left: 1px;
  margin-top: -6px;
  border-width: 5px;
  border-style: solid;
  border-color: #ff9292 #ff9292 transparent transparent;
  z-index: 1000;
}

.paper-badge.bestseller-badge {
  position: relative;
  top: 0;
  left: 0;
}

.paper-badge.bestseller-badge::after {
  content: '';
  display: none;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px !important;
  color: #525252;
}

.swiper-horizontal {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.preview-bookmark {
  border: 1px solid #636363;
  border-radius: 8px;
  padding: 8px 13px !important;
}

.preview-bookmark:hover {
  border: 1px solid #636363 !important;
  background-color: #fff !important;
}

.ratings-box .card {
  min-height: 350px;
  box-shadow: 0px 0px 8px 0px #0000001f !important;
  border: 1px solid #ededed;
}

/* full modal */
@media only screen and (min-width: 576px) {
  .full-modal .modal-dialog {
    max-width: 90%;
  }
}

.max-width-800 {
  max-width: 800px;
}

.question-paper-title:hover {
  color: var(--bs-primary) !important;
}

/* Added This Style To Avoid Sonarqube Errors */
.button-as-span {
  background: none;
  border: none;
  padding: 0;
  color: inherit;
  font: inherit;
  cursor: pointer;
}

.button-as-div {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.button-as-div:disabled {
  cursor: not-allowed;
}

.button-as-p {
  background: none;
  border: none;
  padding: 0;
  color: inherit;
  font: inherit;
  cursor: pointer;
}
